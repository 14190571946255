import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import classnames from 'classnames';
import {
  CardBody,
  CardHeader,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import TransactionDetailsPopup from './TransactionDetailsPopup';
import { getRewardTransactions } from '../../../actions/rewards/index';
import { formatDate } from '../../../helpers/formatDate';
import { formatTime } from '../../../helpers/formatTime';
import InnerLoader from '../../../components/Common/InnerLoader';

const TransactionTable = ({ rewardTypeId = null, rewardId = null }) => {
  const [cardHeaderTab, setCardHeaderTab] = useState('1');
  const [isRight, setIsRight] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const cardHeaderToggle = (tab) => {
    if (cardHeaderTab !== tab) {
      setCardHeaderTab(tab);
    }
  };

  const TransactionDetailsAll = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Akhil',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '6',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Rashid',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '7',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      customerName: 'jamal',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      AhlanId: '2333223232',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '8',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'SRK',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '9',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      customerName: 'Ali',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '10',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      customerName: 'Shameem',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50.20 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsEarned = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '5',
      date: 'Tue 19 Dec',
      time: '08:36 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Akhil',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 50 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '6',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Rashid',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsRedeem = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
    {
      id: '3',
      date: 'Tue 19 Dec',
      time: '08:30 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Sajeer K',
      description: 'Earned',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      descriptionNote: 'Store: Itc, Ref: SO129786',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '4',
      date: 'Tue 19 Dec',
      time: '08:31 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Rishal',
      ahlanTier: 'Platinum',
      ahlanTierClass: 'platinum',
      description: 'Earned',
      descriptionNote: 'Store: danubehome.com, Ref: SO123456',
      amount: '+ 1000 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-up-right',
      expDate: 'Tue 19 Jan 2024',
    },
  ];
  const TransactionDetailsReturns = [
    {
      id: '1',
      date: 'Tue 19 Dec',
      time: '08:00 AM',
      transactionId: '132323232323',
      AhlanId: '21213323232',
      customerName: 'Abdul',
      ahlanTier: 'Silver',
      ahlanTierClass: 'silver',
      description: 'Earned',
      descriptionNote: 'Store: Barsha, Ref: SO123456',
      amount: '+ 500 Nos',
      transactionType: 'Credited',
      feaIconClass: 'text-success',
      feaIcon: 'arrow-down-right',
      expDate: 'Tue 19 Jan 2024',
    },
    {
      id: '2',
      date: 'Tue 19 Dec',
      time: '08:20 AM',
      transactionId: '13232325444',
      AhlanId: '2333223232',
      customerName: 'Bari',
      ahlanTier: 'Gold',
      ahlanTierClass: 'gold',
      description: 'Redeemed',
      descriptionNote: 'Store: danubehome.com, Ref: SO123476',
      amount: '- 100 Nos',
      transactionType: 'Debited',
      feaIconClass: 'text-danger',
      feaIcon: 'arrow-up-right',
    },
  ];

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const getRewardTransactionsFunc = async () => {
    try {
      setIsLoading(true);
      const inputData = {
        pageOffset: pageOffset,
        pageSize: pageSize,
        rewardTypeId: rewardTypeId,
        rewardId: rewardId,
        filters: {
          status: [],
        },
      };

      if (status) {
        inputData.filters.status = [status];
      }

      const { success, data } = await getRewardTransactions(inputData);

      if (success) {
        setTransactions(data?.data);
        setTotalPages(data?.totalPages);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching reward transactions:', error);
    }
  };

  useEffect(() => {
    getRewardTransactionsFunc();
  }, [pageOffset, status, rewardTypeId]);

  const tabs = [
    {
      label: 'All',
      id: '1',
      value: '',
    },
    { label: 'Hold', id: '2', value: 'hold' },
    { label: 'Approved', id: '3', value: 'approved' },
    { label: 'Canceled', id: '4', value: 'canceled' },
  ];

  const pages = [...Array(totalPages)?.keys()];

  return (
    <React.Fragment>
      <div className="table-card">
        <div className="card-header align-items-center d-flex">
          <div className="flex-shrink-0 ms-2">
            <Nav
              tabs
              className="nav justify-content-end nav-tabs-custom rounded card-header-tabs border-bottom-0"
            >
              {tabs?.map((tab) => {
                return (
                  <NavItem>
                    <NavLink
                      style={{ cursor: 'pointer' }}
                      className={classnames({
                        active: cardHeaderTab === tab?.id,
                      })}
                      onClick={() => {
                        cardHeaderToggle(tab?.id);
                        setStatus(tab?.value);
                        setPageOffset(0);
                      }}
                    >
                      {tab?.label}
                    </NavLink>
                  </NavItem>
                );
              })}

              {/* <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '2' })}
                  onClick={() => {
                    cardHeaderToggle('2');
                  }}
                >
                  Earned
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '3' })}
                  onClick={() => {
                    cardHeaderToggle('3');
                  }}
                >
                  Redeem
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: 'pointer' }}
                  className={classnames({ active: cardHeaderTab === '4' })}
                  onClick={() => {
                    cardHeaderToggle('4');
                  }}
                >
                  Returns
                </NavLink>
              </NavItem> */}
            </Nav>
          </div>
        </div>
        <CardBody className="border-right border-left">
          <TabContent activeTab={cardHeaderTab} className="text-muted">
            <TabPane tabId={cardHeaderTab} id={cardHeaderTab}>
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Transaction ID</th>
                      {!rewardId ? <th>Reward ID</th> : null}
                      {!rewardId ? <th>Customer Name</th> : null}
                      <th>Type</th>
                      <th>Points</th>
                      <th>Status</th>
                      <th>Created At</th>
                      {!rewardId ? <th> </th> : null}
                    </tr>
                  </thead>
                  <tbody>
                    {(transactions || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{key + 1}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">
                                #{item?.transactionId}
                              </h5>
                            </div>
                          </div>
                        </td>
                        {!rewardId ? (
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <h5 className="fs-13 my-1">
                                  #{item?.rewardId}
                                </h5>
                              </div>
                            </div>
                          </td>
                        ) : null}

                        {!rewardId ? (
                          <td>
                            <div className="d-flex align-items-center">
                              <div>
                                <h5 className="fs-13 my-1">
                                  {item?.memberName}
                                </h5>
                              </div>
                            </div>
                          </td>
                        ) : null}

                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1 text-capitalize">
                                {item?.type}
                              </h5>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5
                                className={`fs-13 my-1 text-capitalize text-success ${
                                  item?.type === 'earn'
                                    ? 'test-success'
                                    : 'text-danger'
                                }`}
                              >
                                {item?.type === 'earn' ? '+ ' : '- '}
                                {item?.points}
                              </h5>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1 text-capitalize">
                                {item?.status}
                              </h5>
                            </div>
                          </div>
                        </td>

                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            {formatDate(item?.createdAt)}
                          </h5>
                          <span className="fs-10 text-muted">
                            {formatTime(item?.createdAt)}
                          </span>
                        </td>
                        {!rewardId ? (
                          <td>
                            <TransactionDetailsPopup />
                          </td>
                        ) : null}

                        {/* <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td> */}
                        {/* <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td> */}
                        {/* <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            {/* <TabPane tabId="2" id="ahlan-earned">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsEarned || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>
            <TabPane tabId="3" id="ahlan-redeem">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsRedeem || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane>*/}
            {/* <TabPane tabId="4" id="ahlan-returns">
              <div className="table-responsive table-card">
                <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Date</th>
                      <th>Transaction ID</th>
                      <th>Ahlan ID</th>
                      <th>Ahlan Tier</th>
                      <th>Description</th>
                      <th>Points</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {(TransactionDetailsReturns || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              <h5 className="fs-13 my-1">{item.id}</h5>
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">{item.date}</h5>
                          <span className="fs-10 text-muted">{item.time}</span>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.transactionId}
                          </h5>
                        </td>
                        <td>
                          <h5 className="fs-13 my-1 fw-normal">
                            #{item.AhlanId}
                          </h5>
                          <span className="fs-10 text-muted">
                            {item.customerName}
                          </span>
                        </td>
                        <td>
                          <h5
                            className={`fs-10 my-1  badge ${item?.ahlanTierClass}`}
                          >
                            {item.ahlanTier}
                          </h5>
                        </td>
                        <td>
                          <div className="d-flex gap-2">
                            <span className={`${item?.feaIconClass}`}>
                              <FeatherIcon
                                icon={item?.feaIcon}
                                style={{ width: 16 }}
                              />
                            </span>
                            <div>
                              <h5 className="fs-13 my-1 fw-normal">
                                {item.description}
                              </h5>
                              <p className="fs-10 mb-0 text-muted">
                                {item.descriptionNote}
                              </p>
                              {item.expDate ? (
                                <p className="fs-10 mb-0 text-warning">
                                  Expired on {item.expDate}
                                </p>
                              ) : null}
                            </div>
                          </div>
                        </td>
                        <td>
                          <h5
                            className={`${item?.feaIconClass} fs-13 my-1 fw-semiBold`}
                          >
                            {item.amount}
                          </h5>
                        </td>
                        <td>
                          <TransactionDetailsPopup />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </TabPane> */}
          </TabContent>
        </CardBody>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm"></div>
        {totalPages > 1 ? (
          <div className="col-sm-auto mt-3 mt-sm-0">
            <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
              <li className={`page-item  ${pageOffset <= 1 ? 'disabled' : ''}`}>
                <Link
                  to="#"
                  className="page-link"
                  onClick={() => {
                    setPageOffset(pageOffset - 1);
                  }}
                >
                  ←
                </Link>
              </li>

              {pages?.map((page, index) => {
                return (
                  <li
                    className={`page-item ${
                      pageOffset === index ? 'active' : ''
                    }`}
                  >
                    <Link
                      to="#"
                      className="page-link"
                      onClick={() => setPageOffset(index)}
                    >
                      {index + 1}
                    </Link>
                  </li>
                );
              })}

              <li className="page-item">
                <Link
                  to="#"
                  className={`page-link ${
                    pageOffset >= totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    setPageOffset(pageOffset + 1);
                  }}
                >
                  →
                </Link>
              </li>
            </ul>
          </div>
        ) : null}
      </div>

      {isLoading ? <InnerLoader /> : null}

      {/*  */}
      <Offcanvas
        isOpen={isRight}
        toggle={toggleRightCanvas}
        id="offcanvasRight"
        direction="end"
      >
        <OffcanvasHeader toggle={toggleRightCanvas} id="offcanvasRightLabel">
          Recent Acitivity
        </OffcanvasHeader>
        <OffcanvasBody>...</OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  );
};

export default TransactionTable;
