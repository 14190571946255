import React, { useEffect, useState } from 'react';
import {
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  ButtonGroup,
} from 'reactstrap';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import { createOrUpdateReward } from '../../../actions/rewards';
import { FilePond, registerPlugin } from 'react-filepond';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import './style.scss';
import { useSelector, useDispatch } from 'react-redux';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { uploadFile } from '../../../actions/upload';
import QueryBuilderApp from '../../Promotion/QueryBuilderApp';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  createOrUpdateRewardTiers,
  getRewardTiers,
} from '../../../actions/rewards/tier';
import { getAllFields } from '../../../actions/promotion/fields';

const countryOptions = [
  { value: 'ae', label: 'UAE' },
  { value: 'om', label: 'Oman' },
  { value: 'bh', label: 'Bahrain' },
  { value: 'qa', label: 'Qatar' },
];

const brandOptions = [
  { value: 'milano', label: 'Milano' },
  { value: 'danubehome_online', label: 'Danube Home' },
  { value: 'properties', label: 'Danube properties' },
];

const RewardTierForm = ({
  submitHandler,
  reward = null,
  rewardTypeId = null,
}) => {
  const [selectedTierIndex, setSelectedTierIndex] = useState(0);
  const [isAddNewTier, setIsAddNewTier] = useState(false);
  const dispatch = useDispatch();

  const getFields = async () => {
    await getAllFields(
      {
        pageOffset: 0,
        pageSize: 100,
      },
      dispatch
    );
  };

  const initialValues = {
    tiers: [
      {
        rewardTypeId: reward?.rewardTypeId,
        tierName: '',
        tierRules: [
          {
            brands: [],
            countries: [],
            earnPointThreshold: null,
            rule: { combinator: 'and', rules: [] },
          },
        ],
      },
    ],
  };

  const validationSchema = Yup.object({
    tiers: Yup.array()
      .min(1, 'At least one tier is required')
      .of(
        Yup.object({
          rewardTypeId: Yup.string().required('Reward TypeId is required'),
          tierName: Yup.string().required('Tier Name is required'),
          tierRules: Yup.array()
            .of(
              Yup.object({
                brands: Yup.array()
                  .of(Yup.string().required('Brand is required'))
                  .min(1, 'At least one brand is required'),
                countries: Yup.array()
                  .of(Yup.string().required('Country is required'))
                  .min(1, 'At least one country is required'),
                earnPointThreshold: Yup.number()
                  .typeError('Please enter a valid number')
                  .min(0.1, 'Threshold must be greater than or equal to 0.1')
                  .required('Earn Point Threshold is required'),
                rule: Yup.object().required('Rule is required'),
              })
            )
            .min(1, 'At least one rule is required'),
        })
      ),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (isAddNewTier) {
          setSelectedTierIndex(values?.tiers?.length || 0);
          setFieldValue('tiers', [
            ...values?.tiers,
            {
              tierName: '',
              rewardTypeId: reward?.rewardTypeId,
              tierRules: [
                {
                  brands: [],
                  countries: [],
                  earnPointThreshold: null,
                  rule: { combinator: 'and', rules: [] },
                },
              ],
            },
          ]);

          return;
        } else {
          const inputData = { ...values };
          const { success, message } = await createOrUpdateRewardTiers(
            inputData
          );

          if (success) {
            alertSuccess(message);
            submitHandler();
          } else {
            alertError({ message });
          }
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    values,
    isSubmitting,
    setFieldValue,
  } = formik;

  const getRewardTiersFunc = async () => {
    const inputData = {
      pageSize: 10,
      pageOffset: 0,
      rewardTypeId: rewardTypeId,
    };
    const { data, success } = await getRewardTiers(inputData);
    if (success && data?.tiers?.length > 0) {
      setFieldValue('tiers', data?.tiers);
    } else {
      setFieldValue('tiers', initialValues?.tiers);
    }
  };

  useEffect(() => {
    getRewardTiersFunc();
  }, [rewardTypeId]);

  useEffect(() => {
    getFields();
  }, []);

  return (
    <>
      {values?.tiers?.length > 0 && values?.tiers?.[0]?.tierName ? (
        <ButtonGroup className="mb-4">
          {values?.tiers?.map((tier, tierIndex) => {
            return (
              tier?.tierName && (
                <Button
                  color={
                    selectedTierIndex === tierIndex ? 'secondary' : 'light'
                  }
                  onClick={() => {
                    setSelectedTierIndex(tierIndex);
                    setValues({ ...values });
                  }}
                  size="sm"
                >
                  {tier?.tierName}
                </Button>
              )
            );
          })}
        </ButtonGroup>
      ) : null}
      <div className="reward-type-form">
        <FormikProvider value={formik}>
          <Form onSubmit={formik.handleSubmit}>
            {values?.tiers?.map(
              (tier, tierIndex) =>
                tierIndex === selectedTierIndex && (
                  <div key={tierIndex}>
                    <FormGroup>
                      <Label htmlFor={`tiers.${tierIndex}.tierName`}>
                        Tier Name
                      </Label>
                      <Input
                        id={`tiers.${tierIndex}.tierName`}
                        name={`tiers.${tierIndex}.tierName`}
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.tiers?.[tierIndex]?.tierName}
                        invalid={
                          touched?.tiers?.[tierIndex]?.tierName &&
                          !!errors?.tiers?.[tierIndex]?.tierName
                        }
                        disabled={values.tiers?.[tierIndex]?._id}
                      />
                      <FormFeedback>
                        {errors?.tiers?.[tierIndex]?.tierName}
                      </FormFeedback>
                    </FormGroup>
                    {tier?.tierRules?.map((rule, ruleIndex) => (
                      <Card className="shadow-none border" key={ruleIndex}>
                        <CardHeader>
                          <Row>
                            <Col className="d-flex align-items-center gap-4">
                              <div className="d-flex align-items-center gap-2">
                                {/* <i className="bx bx-grid-vertical ms-2 me-2 fs-18"></i> */}
                                <span variant="subtitle2" display="block">
                                  {`Rule ${ruleIndex + 1}`}
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="border-bottom">
                          <FormGroup>
                            <Label
                              htmlFor={`tiers.${tierIndex}.tierRules.${ruleIndex}.brands`}
                            >
                              Brands
                            </Label>
                            <Select
                              id={`tiers.${tierIndex}.tierRules.${ruleIndex}.brands`}
                              name={`tiers.${tierIndex}.tierRules.${ruleIndex}.brands`}
                              options={brandOptions}
                              isMulti
                              onChange={(selectedOptions) => {
                                const values = selectedOptions?.map(
                                  (option) => option.value
                                );
                                formik.setFieldValue(
                                  `tiers.${tierIndex}.tierRules.${ruleIndex}.brands`,
                                  values
                                );
                              }}
                              onBlur={handleBlur}
                              value={brandOptions?.filter((option) =>
                                values?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.brands?.includes(option?.value)
                              )}
                              className={
                                touched?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.brands &&
                                !!errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.brands
                                  ? 'is-invalid'
                                  : ''
                              }
                            />
                            <FormFeedback>
                              {
                                errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.brands
                              }
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor={`tiers.${tierIndex}.tierRules.${ruleIndex}.countries`}
                            >
                              Countries
                            </Label>
                            <Select
                              id={`tiers.${tierIndex}.tierRules.${ruleIndex}.countries`}
                              name={`tiers.${tierIndex}.tierRules.${ruleIndex}.countries`}
                              options={countryOptions}
                              isMulti
                              onChange={(selectedOptions) => {
                                const values = selectedOptions?.map(
                                  (option) => option?.value
                                );
                                formik.setFieldValue(
                                  `tiers.${tierIndex}.tierRules.${ruleIndex}.countries`,
                                  values
                                );
                              }}
                              onBlur={handleBlur}
                              value={countryOptions?.filter((option) =>
                                values?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.countries?.includes(option?.value)
                              )}
                              className={
                                touched?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.countries &&
                                !!errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.countries
                                  ? 'is-invalid'
                                  : ''
                              }
                            />
                            <FormFeedback>
                              {
                                errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.countries
                              }
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label
                              htmlFor={`tiers.${tierIndex}.tierRules.${ruleIndex}.earnPointThreshold`}
                            >
                              Earn Point Threshold
                            </Label>
                            <Input
                              id={`tiers.${tierIndex}.tierRules.${ruleIndex}.earnPointThreshold`}
                              name={`tiers.${tierIndex}.tierRules.${ruleIndex}.earnPointThreshold`}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.earnPointThreshold
                              }
                              invalid={
                                touched?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.earnPointThreshold &&
                                !!errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.earnPointThreshold
                              }
                            />
                            <FormFeedback>
                              {
                                errors?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.earnPointThreshold
                              }
                            </FormFeedback>
                          </FormGroup>
                          <FormGroup>
                            <Label>Conditions</Label>
                            <QueryBuilderApp
                              updateQuery={(value) =>
                                setFieldValue(
                                  `tiers.${tierIndex}.tierRules.${ruleIndex}.rule`,
                                  value
                                )
                              }
                              data={
                                values?.tiers?.[tierIndex]?.tierRules?.[
                                  ruleIndex
                                ]?.rule
                              }
                              isUseRewardTierRuleGroup={true}
                              fieldGroups={['reward']}
                            />
                            {touched?.tiers?.[tierIndex]?.tierRules?.[ruleIndex]
                              ?.rule &&
                            !!errors?.tiers?.[tierIndex]?.tierRules?.[ruleIndex]
                              ?.rule ? (
                              <FormFeedback>
                                {
                                  errors?.tiers?.[tierIndex]?.tierRules?.[
                                    ruleIndex
                                  ]?.rule
                                }
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                        </CardBody>
                      </Card>
                    ))}

                    <div className="d-flex flex-row mb-4 ">
                      <div className="px-3 py-5 bg-light border rounded-1 border-soft-dark text-center full-width">
                        <h4>Add rules and configure conditions</h4>
                        <p>
                          You can add more than one rule with different
                          conditions and effects by clicking on the Create Rule
                          button
                        </p>
                        <button
                          type="button"
                          className="btn btn-primary waves-effect waves-light d-flex gap-1 m-auto"
                          onClick={() => {
                            setFieldValue(`tiers.${tierIndex}.tierRules`, [
                              ...values?.tiers?.[tierIndex]?.tierRules,
                              {
                                rewardTypeId:
                                  reward?.rewardTypeId || rewardTypeId,
                                brands: [],
                                countries: [],
                                earnPointThreshold: null,
                                rule: { combinator: 'and', rules: [] },
                              },
                            ]);
                          }}
                        >
                          <i className="bx bx-plus fs-18"></i> Create Rule
                        </button>
                      </div>
                    </div>

                    <hr />
                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        outline
                        color="danger"
                        size="md"
                        onClick={(e) => {
                          e?.stopPropagation();
                          setValues({ ...initialValues });
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        loading={false}
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                        onClick={() => {
                          setIsAddNewTier(true);
                        }}
                        type="submit"
                      >
                        Add One More Tier
                      </Button>

                      <Button
                        loading={false}
                        type="submit"
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                        onClick={() => {
                          setIsAddNewTier(false);
                        }}
                      >
                        Save & Next
                      </Button>
                    </div>
                  </div>
                )
            )}
          </Form>
        </FormikProvider>

        {isSubmitting ? <InnerLoader /> : null}
      </div>
    </>
  );
};

export default RewardTierForm;
