import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
// material-ui
import {
  Button,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  ModalFooter,
} from 'reactstrap';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// project-imports
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import {
  updatePromotionSchedule,
  updatePromotionProductEligibility,
} from '../../../actions/promotion/index';
import { getRewardsMaster } from '../../../actions/rewards';
import { QueryBuilderApp } from '../QueryBuilderApp';
import { v4 as uuidv4 } from 'uuid';
import Tier from './Tier';
import BasketPlayground from '../BasketPlayground';
import { EFFECT_TYPES } from '../../../constants';
import { convertFieldToQueryBuilderFormat } from '../../../helpers/convertFieldToQueryBuilderFormat';
import { getAllFields } from '../../../actions/promotion/fields';
import { useDispatch } from 'react-redux';
import InnerLoader from '../../../components/Common/InnerLoader';

// ==============================|| PROMOTION TIERS ||============================== //

const discountTypes = [
  {
    label: 'Percentage',
    value: 'percentage',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },

  {
    label: 'Group',
    value: 'segmented',
  },
  {
    label: 'Group - Unit Based',
    value: 'unitBased',
  },
  {
    label: 'Combo',
    value: 'combo',
  },
  {
    label: 'Gift',
    value: 'gift',
  },
];

const rewardTypes = [
  {
    label: 'Fixed',
    value: 'fixed',
  },
  {
    label: 'Formula',
    value: 'rewardFormula',
  },
];

const walletTypes = [
  {
    label: 'Formula',
    value: 'walletFormula',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
];

const PromotionTiers = ({
  onCancel,
  getStores = () => {},
  promotionId,
  promotionDetail,
  updateHandler = () => {},
}) => {
  const navigate = useNavigate();

  const { schedule, basicSettings } = promotionDetail || { schedule: null };

  const [tiers, setTiers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rewardList, setRewardList] = useState([]);

  const getRewardsList = async () => {
    try {
      const data = {
        pageOffset: 0,
        pageSize: 100,
      };
      const res = await getRewardsMaster(data);
      setRewardList(
        res?.data?.rewardTypes?.map((rw) => {
          return {
            label: rw?.name || '',
            value: rw?.rewardTypeId || '',
          };
        }) || []
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRewardsList();
  }, []);

  const [productEligibilityQuery, setProductEligibilityQuery] = useState({
    combinator: 'and',
    rules: [],
  });
  const dispatch = useDispatch();

  const getFields = async () => {
    const { data } = await getAllFields(
      {
        pageOffset: Math.max(0),
        pageSize: 2000,
      },
      dispatch
    );

    // setFields(convertFieldToQueryBuilderFormat(data));
  };

  useEffect(() => {
    if (promotionDetail) {
      setProductEligibilityQuery({
        ...promotionDetail?.productEligibility?.rule,
      });
      setTiers(promotionDetail?.productEligibility?.rulesAndEffects);
      getFields();
    }
  }, [promotionDetail]);

  const updatePromotionProductEligibilityFunc = async () => {
    setIsLoading(true);

    const variables = {
      input: {
        promotionId: promotionId,
        productEligibility: {
          rule: productEligibilityQuery,
          rulesAndEffects: tiers,
        },
      },
    };

    const { data, success } = await updatePromotionProductEligibility(
      variables
    );

    if (success) {
      updateHandler();
      alertSuccess('Promotion product eligibility updated successfully.');
    } else {
      alertError('Failed!, Please try after some time.');
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const effectChangeHandler = (fieldName, value, eIndex, tIndex) => {
    let newEffect = {
      ...tiers?.[tIndex]?.effects?.[eIndex],
      [`${fieldName}`]: value,
    };

    if (fieldName === 'effectType') {
      newEffect = {
        [`${fieldName}`]: value,
        discountType:
          value === 'transactions' ? discountTypes?.[0]?.value : null,
        walletType: value === 'wallet' ? walletTypes?.[0]?.value : null,
        rewardType: value === 'rewardPoints' ? rewardTypes?.[0]?.value : null,
      };
    }

    if (fieldName === 'walletType' && value === 'walletFormula') {
      newEffect = {
        ...newEffect,
        walletAmount: null,
        maxLimit: null,
      };
    }

    if (fieldName === 'walletType' && value === 'fixed') {
      newEffect = {
        ...newEffect,
        walletFormula: null,
      };
    }

    if (fieldName === 'rewardType' && value === 'rewardFormula') {
      newEffect = {
        ...newEffect,
        rewardValue: null,
        maxLimit: null,
      };
    }

    if (fieldName === 'rewardType' && value === 'fixed') {
      newEffect = {
        ...newEffect,
        rewardFormula: null,
      };
    }

    const newEffects = [...tiers?.[tIndex]?.effects];
    newEffects[eIndex] = newEffect;
    const newTier = {
      ...tiers?.[tIndex],
      effects: newEffects,
    };
    const newTiers = [...tiers];
    newTiers[tIndex] = newTier;
    setTiers([...newTiers]);
  };

  const deleteEffectHandler = (eIndex, tIndex) => {
    const newEffects = [
      ...tiers[tIndex]?.effects?.filter((item, index) => eIndex !== index),
    ];
    const newTier = {
      ...tiers[tIndex],
      effects: newEffects,
    };
    const newTiers = [...tiers];
    newTiers[tIndex] = newTier;
    setTiers([...newTiers]);
  };

  const deleteRuleHandler = (tIndex) => {
    const newTiers = [...tiers?.filter((item, index) => index !== tIndex)];
    setTiers([...newTiers]);
  };

  const onDragEnd = async (result) => {
    if (!result?.destination) {
      return;
    }

    if (result?.type === 'tier') {
      const updatedTiers = [...tiers];
      const [removed] = updatedTiers?.splice(result?.source?.index, 1);
      updatedTiers?.splice(result?.destination?.index, 0, removed);
      setTiers([...updatedTiers]);
    }

    if (result?.type === 'effect') {
      const tIndex = Number(result?.destination?.droppableId);

      const newEffects = [...tiers?.[tIndex]?.effects];
      const [removed] = newEffects?.splice(result?.source?.index, 1);
      newEffects?.splice(result?.destination?.index, 0, removed);

      const newTier = {
        ...tiers?.[tIndex],
        effects: newEffects,
      };

      const newTiers = [...tiers];
      newTiers[tIndex] = newTier;
      setTiers([...newTiers]);
    }
  };

  const updateTierQuery = (value, tIndex) => {
    const newTier = {
      ...tiers?.[tIndex],
      rule: value,
    };
    const newTiers = [...tiers];
    newTiers[tIndex] = { ...newTier };
    setTiers([...newTiers]);
  };

  const duplicateEffect = (eIndex, tIndex) => {
    const newEffects = [...tiers?.[tIndex]?.effects];
    newEffects?.splice(eIndex + 1, 0, {
      ...newEffects?.[eIndex],
      id: generateId(),
    });

    const newTier = {
      ...tiers[tIndex],
      effects: newEffects,
    };
    const newTiers = [...tiers];
    newTiers[tIndex] = newTier;
    setTiers([...newTiers]);
  };

  const duplicateRule = (tIndex) => {
    const newTiers = [...tiers];
    newTiers?.splice(tIndex + 1, 0, {
      ...newTiers?.[tIndex],
      id: generateId(),
    });
    setTiers([...newTiers]);
  };

  const generateId = () => {
    return uuidv4();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (promotionId) {
          updatePromotionProductEligibilityFunc();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <CardBody className="p-4">
      <Row columnSpacing={2}>
        <Col lg={7} md={12} className="position-relative">
          <DragDropContext onDragEnd={onDragEnd}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Card className="shadow-none">
                  <CardHeader className="p-0 border-0">
                    <CardTitle className="fs-14 fw-medium mb-0 ">
                      Product Eligibility
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-0">
                    <Row>
                      <QueryBuilderApp
                        data={{ ...productEligibilityQuery }}
                        updateQuery={setProductEligibilityQuery}
                        fieldGroups={['item']}
                      />
                    </Row>
                  </CardBody>
                </Card>

                <Card className="shadow-none rulesEffects">
                  <CardHeader className="p-0 border-0 d-flex ">
                    <CardTitle className="fs-14 fw-medium mb-0 ">
                      Rules and Effects
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-0">
                    <Row>
                      <Col>
                        <Droppable droppableId="droppable" type="tier">
                          {(provided, snapshot) => (
                            <div
                              {...provided?.droppableProps}
                              ref={provided?.innerRef}
                            >
                              {tiers?.map((tier, tIndex) => {
                                return (
                                  <Draggable
                                    key={tier?.id?.toString()}
                                    draggableId={tier?.id?.toString()}
                                    index={tIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided?.innerRef}
                                        {...provided?.draggableProps}
                                      >
                                        <Tier
                                          tier={tier}
                                          tIndex={tIndex}
                                          duplicateRule={duplicateRule}
                                          deleteRuleHandler={deleteRuleHandler}
                                          updateTierQuery={updateTierQuery}
                                          generateId={generateId}
                                          effectTypes={EFFECT_TYPES}
                                          discountTypes={discountTypes}
                                          tiers={tiers}
                                          setTiers={setTiers}
                                          duplicateEffect={duplicateEffect}
                                          effectChangeHandler={
                                            effectChangeHandler
                                          }
                                          deleteEffectHandler={
                                            deleteEffectHandler
                                          }
                                          rewardTypes={rewardTypes}
                                          walletTypes={walletTypes}
                                          rewardList={rewardList || []}
                                          promotionId={promotionId}
                                          draghandleProps={
                                            provided?.dragHandleProps
                                          }
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div className="px-3 py-5 bg-light border rounded-1 border-soft-dark text-center">
                          <h4>
                            Add rules and configure conditions and Effects
                          </h4>
                          <p>
                            You can add more than one rule with different
                            conditions and effects by clicking on the Create
                            Rule button
                          </p>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light d-flex gap-1 m-auto"
                            onClick={() => {
                              setTiers([
                                ...(tiers || []),
                                {
                                  id: generateId(),
                                  rule: { combinator: 'and', rules: [] },
                                  effects: [],
                                },
                              ]);
                            }}
                          >
                            <i className="bx bx-plus fs-18"></i> Create Rule
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {!promotionId?.includes('__v') && (
                  <ModalFooter className="sticky-modal-footer">
                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        outline
                        color="danger"
                        size="md"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>

                      <Button
                        loading={false}
                        type="submit"
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                      >
                        {promotionDetail?.promotionName
                          ? 'Save Draft'
                          : 'Save & Next'}
                      </Button>
                    </div>
                  </ModalFooter>
                )}
              </Form>
            </FormikProvider>
          </DragDropContext>

          {isLoading ? <InnerLoader /> : null}
        </Col>
        <Col>
          <Row
            lg={12}
            md={12}
            marginX={2}
            backgroundColor={'#e3f2fd'}
            borderColor={'#80deea'}
            borderRadius={1}
            height={'100%'}
            width={'100%'}
          >
            <BasketPlayground
              productEligibilityQuery={productEligibilityQuery}
              promotionId={promotionId}
              tiers={tiers}
              promotionDetail={promotionDetail}
            />
          </Row>
        </Col>
      </Row>
    </CardBody>
  );
};

PromotionTiers.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
};

export default PromotionTiers;
